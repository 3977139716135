import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-two',
  templateUrl: './content-two.component.html',
  styleUrls: ['./content-two.component.css']
})
export class ContentTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
